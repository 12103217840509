import { FC, createRef, memo } from 'react';
import styles from '../../../ExpensionMap.module.scss';
import cx from 'classnames';
import { useExpansionMapHelper } from '../../../helper';
import { Districts } from '../../../../../constants/districts';

export const Riehl: FC = memo(() => {
  const ref = createRef<SVGTextElement>();
  const {
    district,
    expansionStatusClass,
    isSelected,
    handleClick,
    handleHover,
  } = useExpansionMapHelper({
    district: Districts.Riehl,
    ref,
  });

  return (
    <>
      <g
        className={cx(styles.districtWrapper, {
          [styles.isSelected]: isSelected,
        })}
        id={district}
        onClick={handleClick}
        onMouseEnter={handleHover}
      >
        <path
          className={cx(styles[expansionStatusClass], styles.districtArea)}
          aria-label="Riehl"
          d="M639.2,519.9l-1.2,3.5c-4.5,12.7-9.7,15.5-17,21.2l0,0c-2.7,2.1-7.8,4.9-12.8,6.8c-5.5,2.1-10.4,4.9-14.7,9 L590,564l-34-22v-44l30-2l22,8l4,14L639.2,519.9z"
        />
        <text
          className={styles.districtName}
          ref={ref}
          transform="matrix(1 0 0 1 575.9289 534.3084)"
        >
          Riehl
        </text>
      </g>
    </>
  );
});
